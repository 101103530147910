const blocks = document.querySelectorAll('ul.social');

function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
}

blocks.forEach((block) => {
    const hoverdiv = document.createElement('div');
    hoverdiv.className = 'socialdetails';
    block.appendChild(hoverdiv);

    block.querySelectorAll('a').forEach((a) => {
        const safeText = escapeHtml(a.href);
        a.addEventListener('mouseover', () => {
            hoverdiv.innerHTML = safeText;
        });
        a.addEventListener('mouseout', () => {
            hoverdiv.innerHTML = '';
        });
    });
});
